<template>
  <td class="fst-row-item">
    <slot v-bind="{ text: getText, to, className }">
      <router-link
        v-if="to"
        :to="to"
        :class="`text-blue-600 ${className}`"
        target="_blank"
        v-bind="$attrs"
        @click="$emit('click')"
      >
        {{ getText }}
      </router-link>

      <div
        v-else
        :class="`${className}`"
        v-bind="$attrs"
        @click="$emit('click')"
      >
        {{ getText }}
      </div>
    </slot>
  </td>
</template>

<script>
import { mapState } from 'vuex'
import { truncate as truncateIt } from '@/plugins/truncate'
import { resolveProp } from '@/utils'

export default {
  name: 'FSTableRowItem',

  inheritAttrs: false,

  props: {
    text: {
      type: [String, Number, Object],
      required: false,
      default: undefined,
    },
    textFallback: {
      type: String,
      required: false,
      default: undefined,
    },
    to: {
      type: [String, Object],
      required: false,
      default: undefined,
    },
    truncate: {
      type: Number,
      required: false,
      default: undefined,
    },
    truncatePrepend: {
      type: [String, Number],
      required: false,
      default: null,
    },
    truncateAppend: {
      type: [String, Number],
      required: false,
      default: null,
    },
    date: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD MMM, YYYY hh:mm:ss a',
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    // use the context item to parse as currency from the state
    // when set to true, porps.text is an object containing all the
    // required properties that resolves state.currency.*attribute*
    asCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  inject: ['$textFallbackAlways', '$textFallbackAlwaysAs'],

  computed: {
    ...mapState({
      organizationCurrencyState: (state) => state.fsTable.organizationCurrency,
      currencyState: (state) => state.fsTable.currency,
      attributesRoot: (state) => state.fsTable.currency.attributes.root,
      attributesActual: (state) => state.fsTable.currency.attributes.actual,
      attributesDefault: (state) => state.fsTable.currency.attributes.default,
      // firstCollectionItem: (state) => state.fsTable.res.data?.[0],
    }),

    getText() {
      if (!this.text) return this.fallbackText()

      if (this.date) {
        if (this.$dayjs().isValid(this.text)) {
          return this.$UTCAwareTime(this.text, this.format)
        }

        return this.textFallback
          ? this.textFallback
          : this.$textFallbackAlways
          ? this.$textFallbackAlwaysAs
          : ''
      }

      if (this.asCurrency) {
        const attrSymbolChild =
          this.currencyState.selected === 'actual'
            ? this.attributesActual.currencySymbol
            : this.attributesDefault.currencySymbol
        const attrSymbol = this.attributesRoot
          ? this.attributesRoot + '.' + attrSymbolChild
          : attrSymbolChild

        const attrAmountChild =
          this.currencyState.selected === 'actual'
            ? this.attributesActual.amount
            : this.attributesDefault.amount
        const attrAmount = this.attributesRoot
          ? this.attributesRoot + '.' + attrAmountChild
          : attrAmountChild

        console.log(attrSymbol, attrAmount)

        const symbol = resolveProp(attrSymbol, this.text)
        const amount = resolveProp(attrAmount, this.text)
        if (symbol && amount) return `${symbol} ${amount}`

        console.warn(
          `could not resolve currency props ${attrAmount}, ${attrSymbol} on this object`,
          this.text
        )
        return this.fallbackText()
      }

      return truncateIt(
        this.text,
        this.truncate,
        this.truncatePrepend,
        this.truncateAppend
      )
    },
  },

  methods: {
    fallbackText() {
      return this.textFallback
        ? this.textFallback
        : this.$textFallbackAlways
        ? this.$textFallbackAlwaysAs
        : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>
