<template>
  <div :id="`${fstId}Container`" class="fst-container">
    <!-- top -->
    <section>
      <div
        class="flex flex-col px-4 mb-5 space-y-3 md:space-y-0 md:flex-row md:justify-between"
      >
        <!-- left -->
        <div
          class="flex flex-col space-y-3 space-x-0 w-full md:space-x-3 md:space-y-0 md:items-center md:flex-row"
        >
          <div>
            <OtoSearch v-if="searchEnabled" v-model="currentSearch" />
          </div>

          <section
            ref="topLeftSlot"
            class="flex flex-col w-full md:flex-row md:max-w-md lg:max-w-lg xl:max-w-xl"
          >
            <slot name="topLeft" v-bind:slotWidth="topLeftSlotWidth" />
          </section>
        </div>

        <!-- right -->
        <div
          class="flex flex-col gap-3 justify-center w-full md:flex-row md:w-96 md:items-center md:justify-end"
        >
          <CurrencyDropdown v-if="currencyEnabled" @change="onChangeCurrency" />

          <ExportAsDropdown @csv="exportAs('csv')" @excel="exportAs('excel')" />
          <slot name="topRight" />
        </div>
      </div>
    </section>
    <!-- /top -->

    <!-- table -->
    <section :id="`${fstId}Wrapper`" class="fst-wrapper">
      <loading
        :active.sync="reqBusy"
        :is-full-page="false"
        :z-index="10"
        :opacity="0.25"
      />

      <table :id="`${fstId}Table`" class="fst">
        <!-- header -->
        <thead class="fst-head">
          <tr class="fst-head-row">
            <th
              v-for="(header, headerIndex) in proxy.headers"
              :key="`fst-head-row-item-${headerIndex}`"
              :width="header.width"
              :class="
                `fst-head-row-item ${header.sort ? 'cursor-pointer' : ''}`
              "
              @click="onSort(header, headerIndex)"
            >
              {{ header.text }}

              <span class="text-blue-800">
                {{
                  header.order === 'asc'
                    ? '⇡'
                    : header.order === 'desc'
                    ? '⇣'
                    : ' '
                }}
              </span>
            </th>
          </tr>
        </thead>
        <!-- /header -->

        <!-- body -->
        <tbody v-if="getData" class="fst-body">
          <!-- use row & row item -->
          <slot :data="getData" />
        </tbody>
        <!-- /body -->
      </table>

      <transition>
        <div
          v-if="!reqBusy && (!getData || getData.length === 0)"
          class="flex flex-col justify-center items-center p-5 my-8 w-full text-center"
        >
          <img
            src="@/assets/placeholder/no-data-undraw.svg"
            class="w-44 h-44 animate-pulse"
            alt="No Data"
          />
          <div class="mt-5 font-semibold text-gray-500 text-14px">
            Oops! No Data Found
            <i class="text-base far fa-meh-blank" />
          </div>
        </div>
      </transition>
    </section>
    <!-- /table -->

    <!-- bottom -->
    <section
      class="flex flex-col items-center px-2 w-full md:flex-row md:justify-between"
    >
      <div
        class="flex justify-center items-center mt-6 w-full md:justify-start md:w-1/3 md:mt-0"
      >
        <span>Show</span>

        <t-select
          v-model.number="currentLimit"
          variant="tableEntry"
          class="ml-2"
          :options="[
            { value: 10, text: '10' },
            { value: 15, text: '15' },
            { value: 25, text: '25' },
          ]"
        />

        <span class="ml-2">Entries</span>
      </div>

      <div class="hidden w-1/3 text-center md:block">
        {{ currentPaginationSummary }}
      </div>

      <div class="w-full md:w-1/3 md:flex md:items-end md:justify-end">
        <t-pagination
          :total-items="currentTotal"
          :per-page="currentLimit"
          v-model="currentPage"
          class="mt-6 mb-4"
          @change="onChangePage($event)"
        />
      </div>
    </section>
    <!-- /bottom -->
  </div>
</template>

<script>
// filter components should only generate queryStrings
// filter qs need to be passed as url prop, fst qs option.prepend = &
// the qs will be merged together by fst
// todo: use store for all of it
// todo: use events
// todo: create a higher order class
// todo: use custom event system
// todo: make hooks for changing queryString
import OtoSearch from '@/components/ui/OtoSearch'
import ExportAsDropdown from '@/components/dropdown/ExportAsDropdown'
import CurrencyDropdown from '@/components/dropdown/CurrencyDropdown'
// import js2excel from 'js2excel'
import { saveAs } from 'file-saver'

export default {
  name: 'FSTable',
  props: {
    // also used as the export name (camelCase converted to snake_case)
    fstId: {
      type: [String, Number],
      required: true,
    },
    endpoint: {
      type: [String, Number],
      required: true,
    },
    // query string option
    qso: {
      type: Object,
      required: false,
      default: () => ({
        prepend: '?',
        append: '',
      }),
    },
    headers: {
      type: Array,
    },
    // currency options
    currencyEnabled: {
      type: Boolean,
      default: false,
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
    currencyAttributes: {
      type: Object,
      default: () => null,
      validator(attr) {
        // todo: use better validation
        return typeof attr.root === 'string' && attr.def && attr.actual
      },
    },
  },
  components: {
    OtoSearch,
    ExportAsDropdown,
    CurrencyDropdown,
  },
  data: () => ({
    // props proxy
    proxy: {
      headers: [],
    },
    topLeftSlotWidth: null,
  }),
  computed: {
    reqBusy() {
      return this.$store.state.fsTable.req.busy
    },
    currentPage: {
      get() {
        return this.$store.getters['fsTable/getPage']
      },
      set(page) {
        // offset will be updated on the store based on the page
        this.$store.commit('fsTable/updatePage', page)
      },
    },
    currentLimit: {
      get() {
        return this.$store.getters['fsTable/getLimit']
      },
      set(val) {
        this.$store.commit('fsTable/updateLimit', val)
      },
    },
    currentOffset: {
      get() {
        return this.$store.getters['fsTable/getOffset']
      },
      set(val) {
        this.$store.commit('fsTable/updateOffset', val)
      },
    },
    currentTotal() {
      return this.$store.getters['fsTable/getTotal']
    },
    currentPaginationSummary() {
      return this.$store.getters['fsTable/getPaginationSummary']
    },
    currentSearch: {
      get() {
        return this.$store.getters['fsTable/getSearch']
      },
      set(val) {
        this.$store.commit('fsTable/updatePage', 1)
        this.$store.commit('fsTable/updateOffset', 0)
        this.$store.commit('fsTable/updateSearch', val)
      },
    },
    getQueryString() {
      // push to the fstable.state.qsa
      // watch in the table component
      // re-fetch
      // this.$log.debug(this.$store.getters['fsTable/getQueryString'])
      return this.$store.getters['fsTable/getQueryString']
    },
    getRes() {
      return this.$store.state.fsTable.res
    },
    getData() {
      return this.$store.state.fsTable.res.data
    },
    getMeta() {
      return this.$store.state.fsTable.res.meta
    },
  },
  async created() {
    //
    window.removeEventListener('resize', this.getTopLeftSlotWidth)
    window.addEventListener('resize', this.getTopLeftSlotWidth)
    this.getTopLeftSlotWidth()

    //
    const isReset = this.$store.state.fsTable.fstId !== this.fstId

    this.$store.dispatch('fsTable/init', {
      fstId: this.fstId,
      endpoint: this.endpoint,
      qso: this.qso,
      reset: isReset,
      currencyEnabled: this.currencyEnabled,
      currencyAttributes: this.currencyAttributes,
    })

    if (isReset) {
      // fetch data will fire meta, data & res events
      await this.fetchData()
    } else {
      const res = this.$store.state.fsTable.res
      this.$emit('meta', res.meta)
      this.$emit('data', res.data)
      // res event isn't available in this case
      // if we need to be consistent, we'll have to store axios response as well
      // for now, it's not important. res event is only helpful for debugging axios raw response
    }
  },
  watch: {
    url: {
      immediate: true,
      deep: false,
      handler(val) {
        this.$store.commit('fsTable/setEndpoint', val)
      },
    },
    headers: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.proxy.headers = this.getMappedHeaders(val)
        }
      },
    },
    async getQueryString(updatedQueryString) {
      console.log({ watchQS: updatedQueryString })
      await this.fetchData(updatedQueryString)
    },
  },
  methods: {
    onSort(sortingItem, sortingItemIndex) {
      // prevent sorting on disabled items (null)
      if (sortingItem.sort === null) {
        return
      }

      // update current item order value to either 'asc' or 'desc' to display arrows
      const currentOrder = this.proxy.headers[sortingItemIndex].order
      this.proxy.headers[sortingItemIndex].order = this.getOrder(currentOrder)

      // todo: commit to store
      // set sorting values to the query strign which will trigger a refetch
      const payload = {
        sort: this.proxy.headers[sortingItemIndex].sort,
        order: this.proxy.headers[sortingItemIndex].order,
      }
      this.$store.commit('fsTable/updateSortAndOrder', payload)

      // do a binary search & reset other sorting to null
      this.proxy.headers.forEach((x) => {
        if (x.text === sortingItem.text) return

        x.order = null
      })
    },
    // eslint-disable-next-line no-unused-vars
    onChangePage(page) {
      // this.$log.debug(pageNo)
      this.$scrollTo(`#${this.fstId}Container`, 200, {
        offset: -150,
      })
    },
    // onDateApply(event) {
    //   if (event.start.split('T')[0]) {
    //     this.proxy.queryString.start_date = event.start.split('T')[0]
    //   } else {
    //     this.proxy.queryString.start_date = event.start
    //   }

    //   if (event.end.split('T')[0]) {
    //     this.proxy.queryString.end_date = event.end.split('T')[0]
    //   } else {
    //     this.proxy.queryString.end_date = event.end
    //   }
    // },
    // onDateCancel() {
    //   this.proxy.queryString.start_date = ''
    //   this.proxy.queryString.end_date = ''
    // },
    getMappedHeaders(data) {
      return data.map((x) => {
        // todo: check for optional width
        // set default sort order
        // order null => not sorted yet || asc or desc => sorted -> show icon
        return { text: x.text, sort: x.sort, width: x.width, order: null }
      })
    },
    getOrder(currentOrder) {
      if (currentOrder === null) {
        // not sorted yet, use 'asc' order (by default, server returns in 'desc' order)
        return 'asc'
      }
      // already sorted, return the opposite order
      return currentOrder === 'asc' ? 'desc' : 'asc'
    },
    async fetchData(queryString = '') {
      if (queryString === '') {
        queryString = this.$store.getters['fsTable/getQueryString']
      }

      //--Note: Special Case--> It is maintained for only Notification filter
      if (queryString.split('&').includes('subcategory=IM')) {
        queryString = queryString.concat('&fetch_illegal_movement')
      }

      await this.$store
        .dispatch('fsTable/fetchData', queryString)
        .then((res) => {
          console.log({ fetchData: res })

          this.$emit('meta', res.data.meta)
          this.$emit('data', res.data.data)
          this.$emit('res', res)
        })
    },
    async exportAs(type = 'excel') {
      const url = this.$store.getters['fsTable/getExportEndpoint']
      // check url
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: 'Processing, please wait...',
          text:
            'Please stay on the tab while the operation is being completed.',
        },
        4000
      )

      try {
        const res = await this.$http.get(url)
        const data = res.data.data
        const dataLength = data.length
        console.log(dataLength, data, Object.keys(data[0]))

        // console.log(data)
        const sDate = this.$store.state?.fsTable?.qsc?.start_date
        const eDate = this.$store.state?.fsTable?.qsc?.end_date
        const dateRange =
          sDate && eDate ? `${sDate}_to_${eDate}` : `lifetime_data`
        const fstIdSnakeCase = this.fstId.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`
        )

        if (
          !Array.isArray(data) &&
          typeof data[0] !== 'object' &&
          data[0] !== null
        ) {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occured!',
            text:
              "We couldn't fetch the data in proper format, please try again later.",
          })

          const consoleMsg =
            'First element of the data array is expected as an object, given: ' +
            typeof data[0]

          console.log(consoleMsg)
          throw new Error(consoleMsg)
        }

        if (!dataLength || dataLength < 1) {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occured!',
            text: 'No data found, please try again later.',
          })
          const consoleMsg = `Data length is: ${dataLength}. Reason could be the applied filter has actually no data or something wrong with the server`

          console.log(consoleMsg)
          throw new Error(consoleMsg)
        }

        if (type === 'excel') {
          await import(/* webpackChunkName: "exceljs" */ 'exceljs')
            .then((ExcelJS) => {
              // initiate workbook
              const workbook = new ExcelJS.Workbook()
              workbook.creator = 'Farhan Israq'
              workbook.created = new Date()
              workbook.lastModifiedBy = 'OTORide System'
              workbook.modified = new Date()

              // prepare the worksheet
              const worksheet = workbook.addWorksheet('Sheet 1', {
                headerFooter: {
                  firstFooter: 'Exported From OTORIde System',
                },
                pageSetup: { paperSize: 9 },
              })

              // map column width based on the value length of the key
              // returns multidimensional array [ [key: string, length:int ]]
              function mapColumnWidth(sampleData = { name: 'Farhan Israq' }) {
                const widthMap = {}
                const [min, max] = [15, 40]
                const predefinedMap = {
                  id: 40,
                  uuid: 40,
                  name: 25,
                  created_at: 25,
                  updated_at: 25,
                  address: 40,
                  email_address: 30,
                }

                for (const key in sampleData) {
                  let keyLength = min

                  if (predefinedMap[key]) {
                    keyLength = predefinedMap[key]
                  } else if (typeof sampleData[key] === 'string') {
                    const valueLength = sampleData[key].length
                    keyLength =
                      valueLength < min
                        ? min
                        : valueLength > min + 5
                        ? min + 10
                        : valueLength > max - 10
                        ? max
                        : valueLength + 4
                  } else if (
                    typeof sampleData[key] === 'object' &&
                    sampleData[key] !== null
                  ) {
                    keyLength = 45
                  }

                  widthMap[key] = keyLength
                }

                // console.log(widthMap?.id, widthMap)
                return widthMap
              }

              // map columnd width from the first element
              const columnWidthMap = mapColumnWidth(data[0])

              // grab the keys of the first object to use as header
              const keys = Object.keys(data[0])

              // map the columns
              const columns = keys.map((x) => {
                return {
                  // convert the header to title case (from snake case)
                  header: x
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w?.substr(1).toLowerCase())
                    .join(' '),
                  key: x,
                  width: columnWidthMap[x] || x.length + 4,
                }
              })

              // define columns
              // console.log({ columns })
              worksheet.columns = columns
              // insert columns
              for (let i = 0; i < dataLength; i++) {
                worksheet.addRow((i + 1, data[i]))
              }

              const fileName = `${fstIdSnakeCase}_otoride_export_${dateRange}.xlsx`

              workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                  new Blob([buffer], { type: 'application/octet-stream' }),
                  `${fileName}`
                )
              })

              // console.log(worksheet)
            })
            .catch((exceljsErr) => {
              // todo: failed toast
              console.warn({ exceljsErr })
            })
        } else if (type === 'csv') {
          const jsonexport = require('jsonexport/dist')

          try {
            const fileName = `${fstIdSnakeCase}_otoride_export_${dateRange}.csv`
            const csv = await jsonexport(data, { rowDelimiter: ';' })
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

            saveAs(blob, fileName)
          } catch (jsonexportErr) {
            console.error({ jsonexportErr })
          }
        } else {
          // todo: toast -> contact super admin
          throw new Error('Unknown export format')
        }
      } catch (err) {
        // todo: toast -> data could not be fetched
        console.log(err)
      }
    },
    getTopLeftSlotWidth() {
      this.$nextTick(() => {
        this.topLeftSlotWidth = this.$refs?.topLeftSlot?.clientWidth || 0
        // console.log({ tlsw: this.topLeftSlotWidth })
      })
    },

    onChangeCurrency(currency) {
      this.$store.commit('fsTable/setCurrencyEnableFlag', {
        flag: this.currencyEnabled,
      })

      this.$store.dispatch('fsTable/switchCurrency', currency)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>
