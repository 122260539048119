<template>
  <div style="width: 140px;">
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="flex justify-between items-center py-0 pl-1.5 font-medium rounded-full border shadow-sm text-14px text-oCharcoal focus:border-oCharcoal hover:border-oCharcoal focus:outline-none"
          style="height: 35px; width: 140px"
          aria-label="Currency Dropdown Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <div class="w-full text-sm text-center text-gray-700">
            {{ getButtonText }}
          </div>
          <div
            class="flex justify-center items-center m-0 bg-gray-100 rounded-r-full"
            style="height:33px; width:35px"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </button>
      </template>

      <template #default="{ hide }" v-if="organizationCurrencyState.all">
        <div class="py-1 mt-px bg-white rounded-md shadow-md">
          <t-dropdown-item
            v-for="item in organizationCurrencyState.all"
            v-bind:key="item.id"
            @click="
              $emit('change', item.id)
              hide()
            "
          >
            {{ item.name }} ({{ item.symbol }})
          </t-dropdown-item>

          <!-- <t-dropdown-item
            @click="
              $emit('change', 'default')
              hide()
            "
          >
            {{ attributesDefault.title }} ({{ getDefaultCurrencyName }})
          </t-dropdown-item> -->
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { resolveProp } from '@/utils'
import { mapState } from 'vuex'

export default {
  name: 'CurrencyDropdown',

  components: {
    TDropdownItem,
  },

  props: {
    actualCurrency: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState({
      organizationCurrencyState: (state) => state.fsTable.organizationCurrency,
      currencyState: (state) => state.fsTable.currency,
      attributesRoot: (state) => state.fsTable.currency.attributes.root,
      attributesActual: (state) => state.fsTable.currency.attributes.actual,
      attributesDefault: (state) => state.fsTable.currency.attributes.default,
      firstCollectionItem: (state) => state.fsTable.res.data?.[0],
    }),

    getActualCurrencyName() {
      if (!this.firstCollectionItem) return '--'

      const attrChild = this.attributesActual.currencyName

      const attr = this.attributesRoot
        ? this.attributesRoot + '.' + attrChild
        : attrChild

      // console.log('attr', attr, this.attributesRoot, this.firstCollectionItem)
      return resolveProp(attr, this.firstCollectionItem)
    },

    getDefaultCurrencyName() {
      if (!this.firstCollectionItem) return '--'

      const attrChild = this.attributesDefault.currencyName

      const attr = this.attributesRoot
        ? this.attributesRoot + '.' + attrChild
        : attrChild

      // console.log('attr', attr)
      return resolveProp(attr, this.firstCollectionItem)
    },

    getButtonText() {
      // return this.currencyState.selected === 'actual'
      //   ? `${this.attributesActual.title} (${this.getActualCurrencyName || ''})`
      //   : `${this.attributesDefault.title} (${this.getDefaultCurrencyName ||
      //       ''})`
      return this.$store.getters['fsTable/getSelectedCurrencyText']
    },
  },
}
</script>

<style></style>
